.users {
  width: 100%;

  p {
    margin: 0;
  }
  .info {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
    //   border: 1px solid red;
    h1 {
      margin: 0;
      padding: 0;
      margin-bottom: 0;
      padding-bottom: 0;
      font-size: 2rem;
    }
    button {
      padding: 0 20px;

      font-size: 1rem;
      cursor: pointer;
      margin: 0;

      height: 2rem;
      background: green;
      border: none;
      color: white;
      border-radius: 0.2rem;
    }
  }
}
