.pdf-viewer {
  position: relative;
  width: 100%;
  background-color: #f5f5f5;
}

.toolbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #f1f1f1;
  padding: 5px 10px;
  border-bottom: 1px solid #ccc;
}

.toolbar-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
}

.toolbar-button span {
  font-size: 16px;
}

.toolbar-scale {
  margin: 0 10px;
}

.toolbar-page-nav {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.toolbar-page-input {
  width: 40px;
  text-align: center;
}

.toolbar-highlight {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.toolbar-highlight input {
  margin-right: 5px;
}

.pdf-page-container {
  position: relative;
  margin: 10px 0;
}

.highlight {
  position: absolute;
  background-color: rgba(255, 255, 0, 0.5);
  pointer-events: none;
}
