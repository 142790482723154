.layout-container {
  font-family: Arial, sans-serif;
  color: #333;
  font-size: 14px;
}

.top-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
}

.center-container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.input-group {
  display: flex;
  align-items: center;
  max-width: 400px;
  margin-bottom: 20px;
}

.input-group .form-control {
  margin-right: 10px;
  width: 20%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.input-group .btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  height: 40px;
}

.sign-out-group .btn-danger {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
}
.admin-pannel{
   margin-right: 1rem;
   background: #007bff;
   color: white;
   padding: .6rem 1rem;
   border-radius: .2rem;
   text-decoration: none;
}

.admin-pannel:hover{
  background:#333333 ;
  text-decoration: none;
  color: white;

}
.content {
  display: flex;
  margin: 1rem 1.5rem;
}

.pdf-thumbnails {
  flex: 1;
  margin: 10px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  max-width: 20%; /* Adjust max-width as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-block {
  flex: 1;
  margin: 10px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  max-width: 50%; /* Adjust max-width as needed */
}

.pdf-viewer {
  flex: 2;
  margin: 10px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  max-width: 50%; /* Adjust max-width as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100vh;
}

.thumbnail {
  cursor: pointer;
  margin-bottom: 10px;
}

.zoom-buttons button {
  margin-right: 5px;
}

.loader-block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.document-thumbnails img,
.document-viewer canvas {
  border-radius: 4px;
}

.download-container {
  margin: 20px;
  padding: 10px;
  text-align: center;
}

.download-container a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  text-decoration: none;
}

/* Styles for the 'Document is not an invoice' panel */
.not-invoice-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  /* margin: 1rem auto; */
  /* border: 1px solid #e0e0e0; */
  border-radius: 5px;
  background-color: whitesmoke;
}

.not-invoice-panel label {
  margin-left: 10px;
  margin-right: 20px;
  margin-bottom: 0 !important;
}

.not-invoice-panel input {
  margin: 0 !important;
}

.not-invoice-panel button {
  padding: 6px 12px;
  background-color: #28a745; /* Green color for the submit button */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.x-scroll {
  overflow-x: scroll;
}

.draggable-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
  /* height: 100%; */
  cursor: col-resize;
  width: 4%;
}

.drag-bars {
  width: 1px;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  height: 16.666666%;
}

/* Additional styles for form columns */
.row {
  display: flex;
  width: 100%;
  flex-wrap: wrap; /* Allows for wrapping if needed */
  align-items: stretch;
}

.col-6 {
  flex: 1;
  padding: 10px;
  max-width: 50%;
}

.col-12 {
  flex: 1;
  padding: 10px;
  max-width: 100%;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }

  .col-6,
  .col-12 {
    max-width: 100%;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    justify-content: space-between; /* Space out the children */
    flex: 1; /* Allow flex children to grow and fill the available space */
    padding: 10px;
  }

  .input-group,
  .not-invoice-panel {
    flex-direction: column;
    align-items: stretch;
  }

  .input-group .btn-primary,
  .not-invoice-panel button {
    margin-top: 10px;
  }
}

.previous-review-data {
  background-color: #fff; /* Light grey background for contrast */
  border-radius: 5px; /* Rounded corners for a card-like feel */
  padding: 15px; /* Inner spacing */
  margin-bottom: 20px; /* Separation from the next form section */
  box-shadow: 0 0 5px 0 lightgray;
  /* max-height: 198px; */
  /* min-height: 198px; */
}

.previous-review-data h4 {
  font-weight: bold; /* Bold font for headings */
  margin-bottom: 10px; /* Space below the heading */
}

/* .previous-review-data .previous-review-content { */
/* padding-left: 20px; Indent content for clarity */
/* } */

/* If icons are used */
.previous-review-data .icon {
  color: #666; /* Icon color */
  margin-right: 5px; /* Space before text */
}

.doc-metadata {
  /* border: 1px solid #ccc; */
  background-color: #fff;
  border-radius: 5px;
  padding: 8px 15px;
  margin: 0 2%;
  box-shadow: 0 0 5px 0 lightgrey;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.doc-metadata h4 {
  font-weight: bold;
  color: #333333 !important;
}

.doc-metadata .doc-metadata-content {
  min-width: 28%;
  text-align: center;
  color: #7a7979;
}
.doc-metadata .doc-metadata-content:nth-child(2) {
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
}

/* If icons are used */
.doc-metadata .icon {
  color: #666; /* Icon color */
  margin-right: 5px; /* Space before text */
}
.react-pdf__Document.document-viewer.display-none {
  display: none;
}

.select-container {
  position: relative;
  width: 100%;
}

select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  appearance: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

select::-ms-expand {
  display: none;
}

select option {
  background-color: #fff;
  color: #333;
  padding: 10px;
}

select:focus {
  outline: none;
  border-color: #6c63ff;
  box-shadow: 0 0 5px rgba(108, 99, 255, 0.5);
}

/* .select-container p {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.select-container:hover select {
  border-color: #aaa; 
}

.select-container:hover p {
  opacity: 1;
} */

.arrow-down {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.is-valid-panel {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* height: 140px; */
}

.is-valid-panel button {
  padding: 6px 12px;
  background-color: #28a745; /* Green color for the submit button */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 1rem auto;
  width: 100%;
}
.use-for-train {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: self-start;
}

.use-for-train input {
  margin-left: 10px;
  cursor: pointer;
}

.swal-modal {
  font-family: Arial, sans-serif; /* Use your preferred font */
  padding: 15px; /* Reduce padding for less chunkiness */
}

/* Styling for the title */
.swal-title {
  font-size: 18px; /* Adjust font size */
  margin: 0 0 10px; /* Adjust margins */
}

/* Styling for the text */
.swal-text {
  font-size: 14px; /* Adjust font size */
  margin: 0; /* Adjust margins */
}

/* Styling for buttons */
.swal-button {
  padding: 5px 15px; /* Reduce padding */
  font-size: 14px; /* Adjust font size */
}

/* highlight.css */



.highlight-area {
  background-color: red; /* Bright color for visibility */
  opacity: 0.2;
  transition: all 0.3s ease;
}

.highlight-area:hover {
  opacity: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Shadow for emphasis on hover */
  background-color: red; /* Easily noticeable color */
}

.download-button-container {
  display: flex;
  justify-content: flex-end; /* Align to the right */
  align-items: center; /* Center align vertically */
  padding: 10px;
  height: 100%; /* Full height to align with the form container */
}

.download-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px; /* Adjust as needed for spacing from the right edge */
}


/* document-display */

.tiff-viewer {
  flex: 2;
  margin: 10px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  max-width: 50%; /* Adjust max-width as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}