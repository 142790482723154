// BG
$main-bg: #f8f9fa;
$soft-bg: #e0e0e0;
$dark-bg: #222b3c;
//TEXT
$main-color: #333;
$soft-color: #333;
$dark-color: #2a3447;
$main-light-color: #f8f9fa;

$primary:#007bff;
$danger:#dc3545;
$success:#28a745;
$white:white;

$admin:#3F826D;
$frontline:#197BBD;
$supervisor:#7C9082;


