@import "../../styles/variables.scss";

.role-detail {
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  border-radius: 0.4rem;

  h1 {
    font-size: 2rem;
  }

  .role-container {
    width: 100%;
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
  }

  .role-card {
    background: $white;
    height: 6rem;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 30px;
    p {
      color: black;
      margin: 0;
      font-size: 1.5rem;
      text-transform: capitalize;
    }
    &:hover {
      text-decoration: none;
    }
  }

  .Admin {
    background: $admin;
    p {
      color: $white;
    }

    &:hover {
      background: $white;

      p {
        color: $admin;
      }
    }
  }

  .Frontline {
    background: $frontline;
    p {
      color: $white;
    }
    &:hover {
      background: $white;

      p {
        color: $frontline;
      }
    }
  }
  .Supervisor {
    background: $supervisor;
    p {
      color: $white;
    }
    &:hover {
      background: $white;

      p {
        color: $supervisor;
      }
    }
  }

  .role-table {
    display: flex;
    width: 100%;
    margin-top: 2rem;
  }
}
