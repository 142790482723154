@import "../../styles/responsive.scss";
@import "./../../styles/variables.scss";

.navbar2 {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid $soft-bg;


  .navbar2-logo {
    display: flex;
    font-weight: bold;    
    span{
      font-size: 1.5rem;
    }
  }
  .icons {
    display: flex;
    align-items: center;
    gap: 20px;
  
  }
}
