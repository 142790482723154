@import "../../styles/variables.scss";

.dataTable {
  width: 100%;

  .dataGrid {
    background: $white;
    padding: 20px;

    .MuiDataGrid-toolbarContainer {
      flex-direction: row-reverse;
    }

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }

    button {
      border: none;
      padding: 0.4rem;
      border-radius: 0.2rem;
      width: 6rem;
      cursor: pointer;
    }

    .action {
      display: flex;
      gap: 15px;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .deactivate {
        color: $danger;
      }

      .activate {
        color: $white;
        background: $success;
      }
    }

    .status-active {
      min-width: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;

      p {
        margin: 0;
        padding: 0;
        padding: 0.3rem;
        color: green;
        font-weight: 500;
      }
    }

    .status-disabled {
      min-width: 5rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;

      p {
        margin: 0;
        padding: 0;
        padding: 0.3rem;
        color: $danger;
      }
    }

    .role-action {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .assign-role {
        background: $primary;
        color: $white;
      }

      .change-role {
        color: $danger;
      }
    }

    .user-not-active {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
}
