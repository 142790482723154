@import "./../../styles/variables.scss";

.role {
  width: 100%;
  height: 100%;
  padding: 2rem;
  border-radius: 0.4rem;

  h1 {
    font-size: 2rem;
  }

  .role-container {
    width: 100%;
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
  }

  .role-card {
    background: $white;
    height: 8rem;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 30px;

    p {
      color: black;
      margin: 0;
      font-size: 1.5rem;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .Admin {
    background: #3f826d;

    p {
      color: white;
    }

    &:hover {
      background: white;

      p {
        color: #3f826d;
      }
    }
  }

  .Frontline {
    background: #197bbd;
    p {
      color: white;
    }
    &:hover {
      background: white;

      p {
        color: #197bbd;
      }
    }
  }
  .Supervisor {
    // background: #204B57;
    background: #7c9082;
    p {
      color: white;
    }
    &:hover {
      background: white;

      p {
        color: #7c9082;
      }
    }
  }

  .role-table {
    display: flex;
    width: 100%;
    margin-top: 2rem;
    background: red;
  }
}
