@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.menu {
  height: 100%;
  position: relative;
  .item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;

    .title {
      font-size: 12px;
      font-weight: 500;
      color: $soft-color;
      text-transform: uppercase;

      @include lg {
        display: none;
      }
    }

    .listItem {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      border-radius: 5px;

      &:hover {
        background-color: $soft-bg;
        text-decoration: none;
        color: $main-color;
      }

      .listItemTitle {
        @include lg {
          display: none;
        }
      }
    }

    .active-list {
      background-color: $main-color;
      color: $main-light-color;
      text-decoration: none;
    }
  }

  .logout-wrapper {
    background: $danger;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: $white;
    padding: 0.8rem;
    border-radius: 0.2rem;
    cursor: pointer;

    img {
      width: 2rem;
    }

    span {
      font-weight: 500;
      text-transform: capitalize;
      font-size: 1.2rem;
    }
  }
}
