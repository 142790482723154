
@import "../../styles/variables.scss";

.container-page-not-found {

    width: 100%;
    height: 100vh;
    background-color: $main-bg;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: .4rem;

    p{
        color: white;
    }

    .go-to-home-page{
        background-color: white;
        color: $dark-color;
        padding: .5rem;
        border-radius: .3rem;
        text-align: center;
        cursor: pointer;

        &:hover {
            background-color: $soft-bg;
            color: $soft-color;
          }
    }
}