@import "../../styles/variables.scss";

.add {
  // width: 100vw;
  // height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.724);
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-user {
    padding: 50px;
    border-radius: 10px;
    background-color: $main-bg;
    position: relative;
    margin-top: -10rem;

    h1 {
      margin-bottom: 30px;
      font-size: 24px;
      color: $soft-color;
      text-align: center;
    }

    .close-icon {
      position: absolute;
      top: 10px;
      right: 20px;
      cursor: pointer;
      color: $main-color;
    }

    form {
      display: flex;
      flex-wrap: wrap;
      max-width: 500px;
      min-width: 400px;
      justify-content: space-between;

      .item {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;

        label {
          font-size: 14px;
          color: $main-color;
        }

        input {
          padding: 10px;
          background-color: transparent;
          color: $main-color;
          outline: none;
          border: 1px solid $soft-color;
          border-radius: 3px;
        }
      }
      button {
        width: 100%;
        padding: 10px;
        cursor: pointer;
        font-size: 1rem;
      }
    }

    .add-btn {
      color: $white;
      background: $success;
      border: none;
      border-radius: 0.2rem;
    }
  }
}
