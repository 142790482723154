/* For WebKit browsers (like Chrome and Safari) */
::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}
::-webkit-scrollbar-x {
  height: 8px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Set the border-radius for a rounded look */
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2; /* Set the color of the scrollbar track */
}

/* For Firefox */
/* You may need to adjust this based on your browser version */
/* For Firefox, you might need to use a special selector like scrollbar-width: thin; */
/* Consult MDN Web Docs for the latest information on Firefox scrollbar styling */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f2f2f2;
}

/* For Microsoft Edge */
/* Edge uses the same scrollbar styling as Firefox, so the Firefox styles should work here */

* {
  scroll-behavior: smooth;
}
body {
  background-color: whitesmoke;
}

.top-navbar {
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid lightgrey;
}

.pdf-pagination {
  border-right: 1px solid lightgrey;
  overflow: auto;
  height: 100vh;
}
.pdf-page-sec > div {
  text-align: center;
  box-shadow: 0px 0px 5px 0px #dedede;
  font-weight: 700;
  margin: 5px 0;
  cursor: pointer;
}
.pdf-page-sec > div > p {
  margin: 5px 0 !important;
}
.react-pdf__Page {
  background-color: whitesmoke !important   ;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-display {
  border-left: 1px solid lightgrey;
  padding: 1rem;
}

.loader-block {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-block {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
}
/* Force Css Inject */

button.btn.btn-info.btn-add.col-xs-12 {
  width: 20% !important;
}

[type="submit"]:not(:disabled) {
  font-size: 1.5rem !important;
  width: 100%;
  color: white !important;
}

/* Force Css Inject */

/* skeleton */

.loading-custom {
  background-color: #ededed;
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, .5) 50%,
    rgba(255, 255, 255, 0) 60%
  ) #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}

.custom-description {
  height: 50vh;
  border-radius: 4px;
  /* animation-delay: .06s; */
}

.custom-title {
  min-height: 6rem;
  border-radius: 4px;
  animation-delay: .10s;
  margin-top: 2rem;
}
.custom-card{
  height: 10rem;
  width: 100%;
  border-radius: 4px;
}

.custom-modal-skeleton{
  height: 10rem;
  /* min-width: 500px; */
  /* height: 10rem; */
  /* border-radius: .2rem; */
}

.custom-modal-wrapper{
   min-width: 46rem;
}

.mb-1{
  margin-bottom: 1rem;
}
.mb-2{
  margin-bottom: 2rem;
}
.mb-3{
  margin-bottom: 3rem;
}

.mt-4{
  margin-top: 4rem;
}